<script>
import { mask } from "vue-the-mask";
import store from "@/store";

export default {
  name: "Security",

  directives: { mask },

  data: () => ({
    security: {},
    shownPassword: {
      new: false,
      confirm: false,
    },
    errorMessages: {},
    rules: {
      required: [(v) => !!v || "Поле обязательно для заполнения!"],
    },
    selects: {
      doctors: [],
    },
    credentials: store.getters["authentication/credentials"],
  }),

  computed: {
    computedRules() {
      return {
        minNumber: this.min,
        samePassword: this.samePassword,
      };
    },
  },

  async beforeMount() {
    await this.getData();
  },

  methods: {
    min(number) {
      return (v) =>
        !v || Number(v) >= number || `Минимальное значение ${number}`;
    },
    samePassword() {
      return (v) => v === this.security.password || `Пароль не совпадает!`;
    },
    async getData() {
      try {
        if (this.credentials.user.position === "patient") {
          this.security = await this.$API.profile().getSettings();
          this.selects.doctors = await this.$API.profile().getDoctors();
          this.security.doctors = this.selects.doctors
            .filter((item) => item.is_allowed)
            .map((item) => item.value);
        } else {
          this.security = {};
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async changeSecurity() {
      try {
        await this.$API.profile().changeSecurity(this.security);
        await this.$API.profile().allowPermission(this.security.doctors);
        await this.$store.dispatch("alert/showSuccess", "Success");
        this.security = {
          ...this.security,
          password: undefined,
          old_password: undefined,
          password_confirmation: undefined,
        };
        this.$refs.form.resetValidation();
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    getPasswordType(key) {
      if (this.shownPassword[key]) {
        return "text";
      }
      return "password";
    },
    togglePasswordType(key) {
      this.shownPassword[key] = !this.shownPassword[key];
    },
  },
};
</script>

<template>
  <v-card elevation="1">
    <v-card-title class="bordered">
      <span>
        {{ $t("profile.headers.security") }}
      </span>
    </v-card-title>
    <v-card-subtitle>
      {{ $t("profile.headers.change_security") }}
    </v-card-subtitle>
    <v-card-text class="mt-3">
      <v-form ref="form" @submit.prevent.stop>
        <v-row>
          <v-col cols="12">
            <v-row class="mt-5">
              <v-col cols="12">
                <v-text-field
                  type="password"
                  :errorMessages="errorMessages.old_password"
                  v-model="security.old_password"
                  dense
                  prepend-icon="mdi-lock"
                  :label="$t('auth.fields.password')"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="security.password"
                  dense
                  :errorMessages="errorMessages.password"
                  prepend-icon="mdi-lock-plus"
                  :label="$t('auth.fields.new_password')"
                  :type="getPasswordType('new')"
                  :rules="rules.required"
                >
                  <template #append>
                    <v-btn
                      @click="togglePasswordType('new')"
                      color="primary"
                      fab
                      x-small
                      text
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :errorMessages="errorMessages.password_confirmation"
                  v-model="security.password_confirmation"
                  dense
                  prepend-icon="mdi-lock-reset"
                  :label="$t('auth.fields.confirm_password')"
                  :type="getPasswordType('confirm')"
                  :rules="[...rules.required, computedRules.samePassword()]"
                >
                  <template #append>
                    <v-btn
                      @click="togglePasswordType('confirm')"
                      color="primary"
                      fab
                      x-small
                      text
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="credentials.user.position === 'patient'" cols="12">
                <v-autocomplete
                  :errorMessages="errorMessages.doctors"
                  multiple
                  chips
                  small-chips
                  :items="selects.doctors"
                  v-model="security.doctors"
                  dense
                  prepend-icon="mdi-account"
                  :label="$t('profile.form.fields.doctors_access')"
                  :rules="[...rules.required]"
                >
                  <template #selection="{ attrs, item, parent, selected }">
                    <v-chip
                      class="ma-1"
                      v-bind="attrs"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item.text }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        mdi-close
                      </v-icon>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-checkbox
        v-if="credentials.user.position === 'patient'"
        :true-value="1"
        :false-value="0"
        v-model="security.personal_data_access"
        class="ml-2"
        :label="$t('profile.form.fields.personal_data_access')"
      ></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn
        @click="changeSecurity()"
        class="py-0"
        color="primary"
        depressed
        small
        style="height: 24px; min-width: 80px"
      >
        {{ $t("global_buttons.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
#pie-chart::v-deep .apexcharts-tooltip-series-group {
  background: rgba(0, 0, 0, 0.75) !important;
}
svg {
  fill: var(--v-primary-base);
}
.bordered {
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--v-primary-base);
    content: " ";
    height: 30px;
    width: 2px;
    border-radius: 50px !important;
  }
}
.pie-list {
  li:not(:last-child) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
.comments-container > div {
  &:hover {
    background: var(--v-greyish-base);
  }
  .status-container {
    .v-icon {
      &:after {
        opacity: 0;
      }
      &.edit-icon {
        &:hover {
          color: var(--v-primary-base);
        }
      }
      &.remove-icon {
        &:hover {
          color: var(--v-error-base);
        }
      }
    }
  }
}
.card-container::v-deep {
  .fb {
    background: #5158d8;
  }
  *:not(.v-btn span) {
    color: #fff;
  }
}
</style>
