<script>
import { mask } from "vue-the-mask";
import store from "@/store";

export default {
  name: "Personal",

  directives: { mask },

  data: () => ({
    user: null,
    credentials: store.getters["authentication/credentials"],
  }),

  async beforeMount() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.user = await this.$API.profile().getPersonalData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async changePersonalData() {
      try {
        const credentials = this.$store.getters["authentication/credentials"];
        const user = { ...this.user };
        user.phones = user.phones.split(",");
        await this.$API.profile().changeData(Number(credentials.user.id), user);
        await this.$store.dispatch("alert/showSuccess", "Changed");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
  },
};
</script>

<template>
  <v-card v-if="user" elevation="1">
    <v-card-title class="bordered">
      <span>
        {{ $t("profile.headers.title") }}
      </span>
    </v-card-title>
    <v-card-subtitle>
      {{ $t("profile.headers.subtitle") }}
    </v-card-subtitle>
    <v-card-text class="mt-3">
      <v-row>
        <v-col cols="12">
          <v-text-field
            :disabled="credentials.user.position === 'doctor'"
            @keyup.enter="changePersonalData"
            dense
            prepend-icon="mdi-account"
            :label="$t('profile.form.fields.name')"
            v-model="user.name"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            disabled
            v-model="user.email"
            prepend-icon="mdi-email"
            :label="$t('profile.form.fields.email')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            @keyup.enter="changePersonalData"
            dense
            v-model="user.phones"
            prepend-icon="mdi-phone"
            :label="$t('profile.form.fields.phone')"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          v-if="credentials.user.position === 'patient' && user.idnp"
        >
          <v-text-field
            disabled
            @keyup.enter="changePersonalData"
            dense
            v-model="user.idnp"
            prepend-icon="mdi-card-account-details-outline"
            label="IDNP"
          ></v-text-field>
        </v-col>
        <v-col v-if="credentials.user.position === 'patient'" cols="12">
          <v-text-field
            @keyup.enter="changePersonalData"
            dense
            v-model="user.address"
            prepend-icon="mdi-home"
            label="Address"
          ></v-text-field>
        </v-col>
        <v-col v-if="credentials.user.position === 'doctor'" cols="12">
          <v-text-field
            disabled
            @keyup.enter="changePersonalData"
            dense
            v-model="user.medical_institution"
            prepend-icon="mdi-home"
            :label="$t('profile.form.fields.medical_institution')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex">
      <v-spacer></v-spacer>
      <v-btn
        class="py-0"
        color="primary"
        depressed
        small
        style="height: 24px; min-width: 80px"
        @click="changePersonalData()"
      >
        {{ $t("global_buttons.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
#pie-chart::v-deep .apexcharts-tooltip-series-group {
  background: rgba(0, 0, 0, 0.75) !important;
}
svg {
  fill: var(--v-primary-base);
}
.bordered {
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--v-primary-base);
    content: " ";
    height: 30px;
    width: 2px;
    border-radius: 50px !important;
  }
}
.pie-list {
  li:not(:last-child) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
.comments-container > div {
  &:hover {
    background: var(--v-greyish-base);
  }
  .status-container {
    .v-icon {
      &:after {
        opacity: 0;
      }
      &.edit-icon {
        &:hover {
          color: var(--v-primary-base);
        }
      }
      &.remove-icon {
        &:hover {
          color: var(--v-error-base);
        }
      }
    }
  }
}
.card-container::v-deep {
  .fb {
    background: #5158d8;
  }
  *:not(.v-btn span) {
    color: #fff;
  }
}
</style>
